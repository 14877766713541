import { Entities } from '@getvim/vim-connect';

const basePayload: Partial<Entities.UIElements.InlineWidget> = {
  id: 'blank',
  url: 'inline-simulator-widget',
  size: { height: '40', width: '100' },
  type: 'INLINE',
  display: true,
};

export function getDiSelectProviderPayload(): Partial<Entities.UIElements.InlineWidget> {
  return {
    ...basePayload,
    section: Entities.UIElements.UISectionNames.diSelectProvider,
  };
}

export function getLabsSelectProviderPayload(): Partial<Entities.UIElements.InlineWidget> {
  return {
    ...basePayload,
    section: Entities.UIElements.UISectionNames.labsSelectProvider,
  };
}

export function getReferralSelectProviderPayload(): Partial<Entities.UIElements.InlineWidget> {
  return {
    ...basePayload,
    section: Entities.UIElements.UISectionNames.referralSelectProvider,
  };
}
