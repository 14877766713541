import type { EditableEncounter } from '@getvim-adapter/api';
import type { DeepRequired } from '@getvim-os/types';
export const editableEncounter: DeepRequired<EditableEncounter> = {
  status: {
    isEditable: false,
    reason: '',
  },
  identifiers: {
    encounterId: {
      isEditable: false,
      reason: '',
    },
  },
  assessment: {
    diagnosisCodes: {
      isEditable: true,
      reason: '',
    },
    generalNotes: {
      isEditable: true,
      reason: '',
    },
  },
  basicInformation: {
    encounterDateOfService: {
      isEditable: false,
      reason: '',
    },
    status: {
      isEditable: false,
      reason: '',
    },
  },
  objective: {
    generalNotes: {
      isEditable: true,
      reason: '',
    },
    physicalExamNotes: {
      isEditable: true,
      reason: '',
    },
  },
  patientInstructions: {
    generalNotes: {
      isEditable: true,
      reason: '',
    },
  },
  plans: {
    generalNotes: {
      isEditable: true,
      reason: '',
    },
    procedureCodes: {
      isEditable: true,
      reason: '',
    },
  },

  provider: {
    providerId: {
      isEditable: false,
      reason: '',
    },
    providerName: {
      isEditable: false,
      reason: '',
    },
  },
  subjective: {
    chiefComplaintNotes: {
      isEditable: true,
      reason: '',
    },
    generalNotes: {
      isEditable: true,
      reason: '',
    },
    historyOfPresentIllnessNotes: {
      isEditable: true,
      reason: '',
    },
    reviewOfSystemsNotes: {
      isEditable: true,
      reason: '',
    },
  },
};
