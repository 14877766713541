import React from 'react';
import { Button } from '@mui/material';

export const InlineSimulatorWidget = () => {
  const buttonStyle = {
    backgroundColor: '#000000',
    padding: 0,
    minWidth: 'auto',
    border: 'none',
    overflow: 'hidden',
  };

  return (
    <Button type="button" style={buttonStyle} disableRipple>
      <img
        src="https://static.getvim.com/img/logos/optimal-care-logo.png"
        alt="Optimal Care Logo"
        title="Optimal Care Logo"
        style={{ width: '100%', height: '100%', objectFit: 'contain', border: 'none' }}
      />
    </Button>
  );
};

InlineSimulatorWidget.displayName = 'InlineSimulatorWidget';
